// 引入封装的axios请求文件
import req from '../utils/request.js'

// 添加
export function pickuptAdd (params) {
  return req.post('pickuptAdd', params)
}

// 列表
export function pickupList (params) {
  return req.post('pickupList', params)
}

// 取消
export function pickupCancel (params) {
  return req.post('pickupCancel', params)
}
