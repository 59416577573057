<template>
  <div class="add-container">
    <div class="add-title">
      Pick Up
    </div>
    <div class="add-cont">
      <a-form ref="formRef" size="medium" :model="form" layout="vertical">
        <a-collapse class="ShipperAddress" expand-icon-position="right" :default-active-key="['1']">
          <a-collapse-item header="Shipper" key="1">
            <template #expand-icon="{ active }">
              <a-button shape="circle" v-if="active">
                <icon-double-down />
              </a-button>
              <a-button shape="circle" v-else>
                <icon-double-up />
              </a-button>
            </template>
            <template #extra>
              <div class="select-address">
                <div class="selecr-title">Address</div>
                <a-config-provider :locale="enUS">
                  <a-select @change="senderAddressVal" v-model="senderSelect" placeholder="Please select" @click.stop=""
                    style="width:360px;">
                    <a-option v-for="item, i in senderAddress.option" :value="item.name" :key="i">{{
                      item.name
                    }}
                    </a-option>
                  </a-select>
                </a-config-provider>
              </div>
            </template>
            <template #header>
              <div class="collapse-header">
                <div class="title">Pick Up Address</div>
                <div class="address">
                  <span v-if="form.name">{{ form.name }},</span>
                  <span v-if="form.company">{{ form.company }},</span>
                  <span v-if="form.street1">{{ form.street1 }},</span>
                  <span v-if="form.street2">{{ form.street2 }},</span>
                  <span v-if="form.city">{{ form.city }},</span>
                  <span v-if="form.state">{{ form.state }}</span>
                </div>
              </div>
            </template>
            <a-row :gutter="[20]">
              <a-col :span="12">
                <a-form-item field="name" label="Name" :rules="[
                  { required: true, message: 'Name is required.' },
                  { maxLength: 35, minLength: 2, message: '2-35 characters.' }
                ]">
                  <a-input v-model="form.name" placeholder="Required 2-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="company" label="Company Name" :rules="[
                  { maxLength: 35, minLength: 2, message: '2-35 characters.' }
                ]">
                  <a-input v-model="form.company" placeholder="Optional 2-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="street1" label="Address Line 1" :rules="[
                  { required: true, message: 'Address line1 is required.' },
                  { maxLength: 35, minLength: 4, message: '4-35 characters.' }
                ]">
                  <a-input v-model="form.street1" placeholder="Required 4-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="street2" label="Address Line 2"
                  :rules="[{ maxLength: 35, minLength: 1, message: '1-35 characters.' }]">
                  <a-input v-model="form.street2" placeholder="Optional 1-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="zip" label="Zip Code" :rules="[
                  {
                    required: true,
                    type: 'number',
                    message: 'Zip code is required.'
                  },
                  { minLength: 5, maxLength: 10, message: '5-10 characters' }
                ]">
                  <a-config-provider :locale="enUS">
                    <a-select allow-search placeholder="Required 5-10 characters" v-model="form.zip" @change="fromChange"
                      @search="fromZipSearch" allow-clear :loading="fromLoading" :options="fromOptions"
                      autocomplete="off">
                      <template #empty>
                        <a-empty v-if="(fromIsempty === true)" />
                        <div class="empty" v-if="(fromZiperror === true)">
                          <a-space>
                            <icon-info-circle />
                            Zip error
                          </a-space>
                        </div>
                        <div class="noarea" v-if="(fromNoarea === true)">
                          <icon-empty />
                          Not in the delivery service area
                        </div>
                      </template>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="city" label="City" :rules="[
                  { required: true, message: 'City is required' },
                  { maxLength: 35, minLength: 2, message: '2-35 characters.' }
                ]">
                  <a-input v-model="form.city" placeholder="Required 2-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="state" label="State" :rules="[
                  { required: true, message: 'State is required' }
                ]">
                  <a-config-provider :locale="enUS">
                    <a-select v-model="form.state" placeholder="Please select" allow-search>
                      <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }} - {{ item.name }}
                      </a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="country" label="Country" :rules="[
                  { required: true, message: 'Country is required' }
                ]">
                  <a-select v-model="form.country" placeholder="Please select" disabled>
                    <a-option value="US">United State</a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="phone" label="Phone" :rules="[
                  { required: true, message: 'Phone is required' },
                  { minLength: 10, maxLength: 15, message: '10-15 characters' }
                ]">
                  <a-input v-model="form.phone" placeholder="Required 5-10 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="2">
                <a-form-item field="phone_extension" label="Extension"
                  :rules="[{ minLength: 1, maxLength: 6, message: '1-6 characters' }]">
                  <a-input v-model="form.phone_extension" placeholder="Optional" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="email" label="Email" :rules="[
                  {
                    match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                    message: 'email validation failed'
                  }
                ]">
                  <a-input v-model="form.email" placeholder="Optional" />
                </a-form-item>
              </a-col>
              <a-col :span="6" class="save">
                <a-form-item field="save_to_addresses">
                  <a-checkbox v-model="form.save_to_addresses">Save to My Address
                  </a-checkbox>
                </a-form-item>
              </a-col>
            </a-row>
          </a-collapse-item>
        </a-collapse>
        <a-card title="Pick Up Information" style="margin-top:24px;">
          <a-row :gutter="[20, 20]">
            <a-col :span="8">
              <a-form-item field="appointment_date" label="Pick Up Date" :rules="[
                { required: true, message: 'Pick up date is required.' }
              ]">
                <a-config-provider :locale="enUS">
                  <a-date-picker v-model="form.appointment_date" placeholder="Select Date" style="width:100%;"
                    :format="dateFormate" />
                </a-config-provider>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item field="appointment_time" label="Pick Up Time" :rules="[
                { required: true, message: 'Pick up time is required.' }
              ]">
                <a-config-provider :locale="enUS">
                  <a-select v-model="form.appointment_time" placeholder="Please select" allow-search>
                    <a-option v-for="item, i in ptimeList" :key="i" :value="item">
                      {{ item }}
                    </a-option>
                  </a-select>
                </a-config-provider>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[20, 20]">
            <a-col :span="8">
              <a-form-item field="num" label="Total Count" :rules="[
                { required: true, message: 'Total count is required.' },
                { type: 'number', max: 10000, min: 1, message: 'Greater than 1 and less than 1000' }
              ]">
                <a-input v-model="form.num" placeholder="Required" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item field="volume" label="Total Volume" :rules="[
                { required: true, message: 'Total volume is required.' },
                { type: 'number', max: 1000, positive: true, message: 'Greater than 0.00 and less than 1000.00' }
              ]">
                <a-input v-model="form.volume" placeholder="Required">
                  <template #suffix>
                    Ft<sup>3</sup>
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item field="weight" label="Total Weight(Lb)" :rules="[
                { required: true, message: 'Total weight is required.' },
                { type: 'number', max: 5000, positive: true, message: 'Greater than 0 and less than 5,000' }
              ]">
                <a-input v-model="form.weight" placeholder="Required">
                  <template #suffix>
                    Lb
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>
      </a-form>
    </div>
    <div class="addnew-footer" id="operateFooter">
      <a-space>
        <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
          @ok="confirmReset" class="warnPop">
          <a-button shape="round">Reset</a-button>
        </a-popconfirm>
        <a-button type="primary" shape="round" @click="submit">Submit</a-button>
      </a-space>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Notification } from '@arco-design/web-vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { IconDoubleDown, IconDoubleUp, IconInfoCircle, IconEmpty } from '@arco-design/web-vue/es/icon'
import local from '@/utils/local'
// 引入api中的接口
import { getZipCode, addressList } from '@/api/order.js'
import { pickuptAdd } from '@/api/pickup.js'
// 使用loading
import { load } from '@/utils/loading.js'
const router = useRouter()
const state = JSON.parse(local.get('configInfo')).state
const ptimeList = JSON.parse(local.get('configInfo')).pick_up_time
const formRef = ref(null)
// 表单提交
const form = reactive({
  name: '',
  company: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  country: 'US',
  zip: '',
  email: '',
  phone: '',
  phone_extension: '',
  save_to_addresses: false,
  appointment_date: '',
  appointment_time: ptimeList[0],
  num: '',
  volume: '',
  weight: ''
})
// sender----------------------------------------
const senderSelect = ref('')
const senderAddress = reactive({
  option: []
})
const getSenderAddress = async () => {
  load.show('Loading...')
  const msg = await addressList({
    page: 1,
    type: 1,
    searchItem: ''
  })
  if (msg.code === 0 && msg.data) {
    load.hide()
    senderAddress.option = msg.data.data
    // 设置默认值
    let defaultAddress = msg.data.data[0]
    form.name = defaultAddress.name
    form.company = defaultAddress.company
    form.street1 = defaultAddress.street1
    form.street2 = defaultAddress.street2
    form.city = defaultAddress.city
    form.state = defaultAddress.state
    form.zip = defaultAddress.zip
    form.phone = defaultAddress.phone
    form.phone_extension = defaultAddress.extension
    form.email = defaultAddress.email
  } else {
    load.hide()
    Notification.error(msg.message)
  }
}
getSenderAddress()
const senderAddressVal = (val) => {
  senderAddress.option.forEach((item) => {
    if (item.name === val) {
      form.name = item.name
      form.company = item.company
      form.street1 = item.street1
      form.street2 = item.street2
      form.city = item.city
      form.state = item.state
      form.zip = item.zip
      form.phone = item.phone
      form.phone_extension = item.extension
      form.email = item.email
    }
  })
}
const numReg = /^[0-9]*$/
const fromOptions = ref([])
const fromData = ref([])
const fromNoarea = ref(false)
const fromIsempty = ref(true)
const fromZiperror = ref(false)
const fromLoading = ref(false)
const fromZipSearch = async (value) => {
  if (!value) {
    fromIsempty.value = true
    fromNoarea.value = false
    fromZiperror.value = false
  } else {
    fromIsempty.value = false
  }
  if (!numReg.test(value)) {
    fromZiperror.value = true
    fromNoarea.value = false
  } else {
    fromZiperror.value = false
    if (value.length > 2) {
      fromLoading.value = true
      const msg = await getZipCode({
        term: value
      })
      if (msg.data.length > 0) {
        fromData.value = msg.data
        fromLoading.value = false
        fromNoarea.value = false
        fromOptions.value = []
        for (let i = 0; i < msg.data.length; i++) {
          fromOptions.value.push(msg.data[i].zip)
        }
      } else {
        fromLoading.value = false
        fromData.value = []
        fromOptions.value = []
        fromNoarea.value = true
      }
    }
  }
}
const fromChange = (value) => {
  if (fromData.value !== []) {
    fromData.value.forEach((item) => {
      if (item.value === value) {
        form.city = item.city
        form.state = item.state
        form.zip = item.zip
      }
    })
  }
}
// 时间设置--------------------------------------------------
let dateFormate = ref('MM/DD/YYYY')
let timeFormate = JSON.parse(local.get('userInfo')).date_format
if (timeFormate === 1) {
  dateFormate.value = 'MM/DD/YYYY'
} else if (timeFormate === 2) {
  dateFormate.value = 'DD/MM/YYYY'
} else if (timeFormate === 3) {
  dateFormate.value = 'YYYY/MM/DD'
}

// 重置
const confirmReset = () => {
  formRef.value.resetFields()
  senderSelect.value = ''
}
// 提交
const submit = () => {
  formRef.value.validate(async (value) => {
    if (!value) {
      console.log('提交通过')
      load.show('Submitting...')
      const msg = await pickuptAdd({
        name: form.name,
        company: form.company,
        street1: form.street1,
        street2: form.street2,
        city: form.city,
        state: form.state,
        country: 'US',
        zip: form.zip,
        email: form.email,
        phone: form.phone,
        phone_extension: form.phone_extension,
        save_to_addresses: form.save_to_addresses,
        appointment_date: form.appointment_date,
        appointment_time: form.appointment_time,
        num: form.num,
        volume: form.volume,
        weight: form.weight
      })
      console.log(msg)
      if (msg.code === 0) {
        load.hide()
        Notification.success('Submited.')
        router.push('/order/pickup/pickuplist')
      } else {
        load.hide()
        Notification.error(msg.message)
      }
    }
  })
}
</script>
<style lang="less" scoped>
.empty {
  color: rgb(var(--danger-6));
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.noarea {
  color: var(--color-text-4);
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

:deep(.arco-collapse-item-content) {
  background-color: #fff;
}

:deep(.arco-form-item-label-required-symbol) {
  display: none;
}

.add-title {
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
  margin: 18px 24px 18px 48px;
}

.add-cont {
  margin: 0 180px 140px 180px;
}

:deep(.arco-spin) {
  display: block;
}

.select-address {
  display: flex;
  margin-right: 28px;
}

.selecr-title {
  line-height: 32px;
  margin-right: 16px;
  color: var(--color-text-2);
  font-weight: 400;
  font-size: 14px;
}

.collapse-header {
  display: flex;

  .title {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .address {
    margin-left: 16px;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
  }
}

.save {
  display: flex;
  align-items: flex-end;
  height: 84px;
  justify-content: flex-end;
}

.add-container {
  position: relative;
}

.addnew-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px;
  padding: 20px 48px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}
</style>
