// 引入封装的axios请求文件
import req from '../utils/request.js'
// 添加订单
export function addOrder (params) {
  return req.post('orderUpdate', params)
}
// order列表
export function orderList (params) {
  return req.post('orderList', params)
}
// 付款打单
export function getLabel (params) {
  return req.post('getLabel', params)
}
// 订单详情
export function orderDetail (params) {
  return req.post('orderDetail', params)
}
// 订单修改回填
export function orderEdit (params) {
  return req.post('editShow', params)
}
// 订单批量处理
export function orderBatchHandle (params) {
  return req.post('orderBatchHandle', params)
}

// 订单取消
export function orderCancel (params) {
  return req.post('orderCancel', params)
}

// 打印订单
export function printLabel (params) {
  return req.post('printLabel', params)
}

// 复制订单
export function duplicateOrder (params) {
  return req.post('duplicateOrder', params)
}

// return订单
export function createReturnLabel (params) {
  return req.post('createReturnLabel', params)
}

// 标记为已经发货
export function markAsShipped (params) {
  return req.post('markAsShipped', params)
}

// zip自动填充
export function getZipCode (params) {
  return req.post('getZipCode', params)
}
// name自动回填
export function getAddressByName (params) {
  return req.post('getAddressByName', params)
}

// address 回填
export function addressList (params) {
  return req.post('addressList/v2', params)
}

// 创建createManifest
export function manifestAdd (params) {
  return req.post('manifestAdd', params)
}

// Manifest列表
export function manifestList (params) {
  return req.post('manifestList', params)
}

// Manifest生成提货的pdf
export function manifestPdf (params) {
  return req.post('manifestPdf', params)
}

// 验证是否可以创建return
export function getCanQuote (params) {
  return req.post('getCanQuote', params)
}

// 验证地址类型
export function getAddressType (params) {
  return req.post('getAddressType', params)
}

// sku搜索
export function getSkuCode (params) {
  return req.post('getSkuCode', params)
}

// 获取上传信息
export function getUploadOrderInfo (params) {
  return req.post('getUploadOrderInfo', params)
}

// 获取上传信息
export function orderImport (params) {
  return req.post('orderImport', params)
}

// 获取上传详情
export function orderUploadDetail (params) {
  return req.post('orderUploadDetail', params)
}

// 修改地址
export function changeAddress (params) {
  return req.post('changeAddress', params)
}

// shipment 单个取消
export function shipmentCancel (params) {
  return req.post('shipmentCancel', params)
}

// shipment 批量删除
export function shipmentBatchCancel (params) {
  return req.post('shipmentBatchCancel', params)
}

// shipment calll back
export function shipmentCallback (params) {
  return req.post('shipmentCallback', params)
}

// manifset移除order
export function manifestRemove (params) {
  return req.post('manifestRemove', params)
}

// 获得pay的总价和详情
export function getMultiOrderQuoteDetail (params) {
  return req.post('getMultiOrderQuoteDetail', params)
}

// 搜索登录以后Tracking id
export function searchLoginTracking (params) {
  return req.post('tracking_logged', params)
}

// 添加备注
export function shipmentAddNote (params) {
  return req.post('shipmentAddNote', params)
}
